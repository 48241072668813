import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';

const AreasListWrapper = styled.div`
  /* ${tw`pb-4`} */
  padding-bottom: 80px;
  .rc-collapse {
    background-color: transparent;
    border-radius: 0;
    /* border: 0; */

    > .rc-collapse-item {
      margin-bottom: 5px;
      /* border-width: 1px; */
      /* border-style: solid; */
      /* border-color: ${themeGet('colors.borderColor', '#f1f1f1')}; */
      /* border-image: initial; */
      /* border-radius: 6px; */
      background-color: #ffffff;
      overflow: hidden;
      ${tw`border rounded border-gray-200 mt-2`}

      > .rc-collapse-header {
        ${tw`px-4 py-4 flex items-center`}
        /* border-bottom: 1px solid; */
        cursor: pointer;
        outline: 0;
        position: relative;
        justify-content: space-between;

        i {
          order: 2;
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          color: ${themeGet('colors.darkBold', '#0D1136')};
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h3 {
          ${tw`text-lg`}
          margin-top: 0px;
          margin-bottom: 0px;
          color: ${themeGet('colors.darkBold', '#0D1136')};
          line-height: 1.5;
        }
      }

      .rc-collapse-content {
        padding: 0px;
        transition: height 0.3s ease-out;
        height: auto;
        /* max-height: 1200px; */
        overflow: hidden;
        /* height: 0px; */

        &.rc-collapse-content-active {
          /* height: 1200px; */
          display: block;
        }

        &.rc-collapse-content-inactive {
          display: none;
          /* height: 0px; */
        }

        > .rc-collapse-content-box {
          box-sizing: border-box;
          margin-top: 0px;
          margin-bottom: 0px;
          a {
          }
          p {
            ${tw`px-4 py-4 flex items-center`}
            color: ${themeGet('colors.darkRegular', '#77798C')};
            line-height: 1.75;
          }
        }
      }
    }
  }
`;

export const AreaItemsWrapper = styled.div`
  display: block;
`;

export const AreaItem = styled.a`
  ${tw`px-4 py-0 hover:bg-primary-lighter border border-gray-100 flex items-center justify-between`}
  /* display: block; */
  cursor: pointer;

  span {
    ${tw`py-2 text-sm`}
    display: block;
    border-bottom-style: solid;
  }

  svg {
    ${tw`text-gray-500`}
  }
`;

export default AreasListWrapper;

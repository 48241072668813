import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';

export const MapWrapper = styled.div`
  ${tw`relative w-full flex-1 mt-4`}

  @keyframes map-pin-bounce {
    0% {
      transform: translateY(-32px);
    }
    40% {
      transform: translateY(-12px);
    }
    80% {
      transform: translateY(-22px);
    }
    to {
      transform: translateY(-12px);
    }
  }
  @keyframes map-pin-up {
    0% {
      transform: translateY(-12px);
    }
    to {
      transform: translateY(-32px);
    }
  }

  .map-marker-shadow {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    border-radius: 50%;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 90px #333;
    background-color: #333;
    opacity: 0.1;

    &.dragging {
      box-shadow: 0 0 0 3px #000;
      background-color: #000;
      opacity: 1;
    }
  }
  .map-marker {
    ${tw`absolute flex-1 left-0 top-0 right-0 bottom-0 items-center justify-center cursor-pointer m-auto`}
    z-index: 2;
    width: 40px;
    height: 40px;

    svg {
      transform: translateY(-18px);
      animation-name: 'map-pin-bounce';
      animation-duration: 0.3s;
      animation-timing-function: ease;
      animation-delay: 0;
      animation-iteration-count: 1;
    }

    &.dragging {
      svg {
        transform: translateY(-32px);
        animation-name: 'map-pin-up';
        animation-duration: 0.1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0;
        animation-iteration-count: 1;
      }
    }
  }
`;

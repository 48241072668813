import React from 'react';


import SearchBox from 'components/SearchBox/SearchBox';
import { AreasWrapper } from './Areas.style';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import Collapse, { Panel } from 'rc-collapse';
import AreasListWrapper, { AreaItem } from './AreaList/AreaList.style';
import { ArrowNext, ArrowPrev } from 'components/AllSvgIcon';
import { useRouter } from 'next/router';
import { toJS } from 'mobx';
import { toast } from 'react-nextjs-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/Spinner/Spinner';
import agent from 'utils/agent';
import { withTranslation } from 'i18n';

function expandIcon({ isActive }) {
  return (
    <i>
      {isActive ? (
        <FontAwesomeIcon
          icon="chevron-up"
          style={{
            color:
              '#a0aebf'
              // process.env.NEXT_PUBLIC_APP == 'Zoyabean' ? '#2DA349' : '#E50071'
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon="chevron-down"
          style={{
            color:
              '#a0aebf'
              // process.env.NEXT_PUBLIC_APP == 'Zoyabean' ? '#2DA349' : '#E50071'
          }}
        />
      )}
    </i>
  );
}

const Areas = observer(({ onAreaSelect, t, i18n }) => {
  const router = useRouter();
  const [areas, setAreas] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  // const [areasFiltered, setAreasFiltered] = React.useState([]);
  const [areasTree, setAreasTree] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState([]);

  const { cartStore } = useStores();
  const { restaurant, orderType } = router.query;

  // // console.log("RESSSS:", state.restaurant);

  React.useEffect(() => {
    // console.log('AREAS:', router.query);
    // api.defaults.headers.common['restaurant-id'] =
    //   cartStore.currentRestaurant.id;

    agent.Data.all('/locations')
      .then((res) => {
        setLoaded(true);
        // console.log('LOCATIONS:', res.data);
        setAreas(res.data);
        // setAreasFiltered(res.data.data);
        generateAreasTree(res.data);
        // setActiveKey(res.data.length == 1 ? [`0`] : null);
        // setLoading(false);
      })
      .catch((err) => {
        toast.notify(`Something went wrong!!!`, {
          title: 'Error.',
          type: 'error'
        });
        // console.log(err);
        // if (err.response) // console.log(err.response);
      });
  }, []);

  const onSearch = (text) => {
    // console.log('TEXT:', text);
    let num = 0;
    let filter = [];
    generateAreasTree(
      areas.filter((item, i) => {
        if (
          item.name[i18n.language].toLowerCase().includes(text.toLowerCase()) ||
          item.parent_name[i18n.language]
            .toLowerCase()
            .includes(text.toLowerCase())
        ) {
          // console.log('FIL', i);
          filter.push(`${num}`);
          num++;
          return true;
        } else {
          return false;
        }
      })
    );
    if (text == '') filter = null;
    // console.log('FI', filter);
    setActiveKey(filter);
  };

  const generateAreasTree = (items) => {
    let lastCity = '';
    const data = [];
    let city = {};
    items.forEach((item) => {
      if (lastCity !== item.parent_name[i18n.language]) {
        // i += 1;
        if (city.areas && city.areas.length) {
          data.push(city);
        }
        city = {};
        city.name = {};
        city.name.en = item.parent_name.en;
        city.name.ar = item.parent_name.ar;
        city.id = item.parent_id;
        city.areas = [];
        lastCity = item.parent_name[i18n.language];
      }
      city.areas.push(item);
    });
    if (city.areas && city.areas.length) {
      data.push(city);
    }

    // console.log('AREA DATA::', data);

    setAreasTree(data);

    if (data.length == 1) setActiveKey([`0`]);

    return data;
  };

  React.useEffect(() => {
    // console.log('ACTIVE ', activeKey);
  }, [activeKey]);

  const onClick = async (area) => {
    // console.log('URL::::', restaurant, area);
    cartStore.setArea(area);
    cartStore.setGeoLocation({});

    if (cartStore.cart.length && cartStore.currentOutlet && cartStore.currentOutlet.areas) {
      const f = cartStore.currentOutlet.areas.filter((el) => el.id === area.id);
      if (toJS(f).length > 0) {
        // console.log('AREA MATCHEDD', area, toJS(f));
      } else {
        toast.notify(
          `You are changing your location. Your cart will be cleared.`,
          {
            title: 'Area changed!',
            type: 'warn'
          }
        );

        // onAreaSelect(area);

        // cartStore.resetOutlet();

        cartStore.loadOutlet(area.id);
        cartStore.resetCart();
        // console.log('AREA DOESNT MATCH', area, toJS(f));
      }
      // onAreaSelect(area, 'reset');
    } else {
      cartStore.resetCart();
      //
    }

    onAreaSelect(area);

    cartStore.setOrderTypeFinished();

    // if (area.id && router.pathname === "/[restaurant]") {
    //   // console.log("Loading Outlet From Area::");
    //   loadOutlet(area);
    // }
  };

  const onChange = (active) => {
    setActiveKey(active);
  };

  // const loadOutlet = async (area) => {
  //   // console.log('Loading Outlet');
  //   api.defaults.headers.common['restaurant-id'] =
  //     cartStore.currentRestaurant.id;
  //   await api.get(`/outlets?area=${area.id}`).then((res) => {
  //     // // console.log("OUTLET DATA:", res.data.data[0]);
  //     const data = res.data.data[0];
  //     cartStore.setOutlet(data);
  //     router.push(
  //       `/[restaurant]/outlets/[store]`,
  //       `/${restaurant}/outlets/${data.url}`
  //       // { shallow: true }
  //     );

  //     return true;
  //   });
  // };

  if (!loaded) {
    return <Spinner />;
  }

  if (!areas.length) {
    return (
      <div className="text-center flex items-center justify-center pt-20">
        Service not available
      </div>
    );
  }

  return (
    <AreasWrapper>
      <SearchBox
        className="headerSearch"
        handleSearch={(value) => onSearch(value)}
        // onClick={onClickHandler}
        placeholder={t('search-area')}
        hideType={true}
        minimal={true}
        showSvg={true}
        style={{ width: '100%' }}
        // value={text || ""}
      />
      {/* <AreaList items={areasTree} onAreaSelect={(area) => onAreaSelect(area)} /> */}
      {orderType && orderType == 'delivery' ? (
        <h4 className="px-4 py-4 text-center">
          Select your area to start ordering
        </h4>
      ) : null}
      <AreasListWrapper>
        <Collapse
          // accordion={true}
          className="accordion border-gray-200 mx-4"
          defaultActiveKey="active"
          expandIcon={expandIcon}
          activeKey={activeKey}
          // activeKey={null}
          onChange={onChange}
          // defaultActiveKey={activeKey}
        >
          {areasTree.length !== 0 ? (
            areasTree.map((item, index) => {
              return (
                <Panel
                  header={
                    <h3 className="flex w-full justify-between items-center">
                      <span>{item.name[i18n.language]}</span>{' '}
                      <span className="text-xs px-2 text-gray-500">
                        {item.areas.length}
                      </span>
                    </h3>
                  }
                  headerClass={`accordion-title ${index}`}
                  key={`${index}`}
                >
                  {/* <AreaItems items={item.areas} /> */}
                  {item.areas.length !== 0 &&
                    item.areas.map((item) => {
                      return (
                        <AreaItem key={item.id} onClick={() => onClick(item)}>
                          <span>{item.name[i18n.language]}</span>
                          {i18n.language === 'ar' ? (
                            <ArrowPrev />
                          ) : (
                            <ArrowNext />
                          )}
                        </AreaItem>
                      );
                    })}
                </Panel>
              );
            })
          ) : (
            <div className="h-full flex flex-col items-center justify-center">
              <div>
                {/* <FontAwesomeIcon
                  icon="exclamation-triangle"
                  style={{
                    color: "#E50071",
                  }}
                  size="4x"
                  className="block"
                /> */}
              </div>
              <p>{t('no-areas-found')}</p>
            </div>
          )}
        </Collapse>
      </AreasListWrapper>
    </AreasWrapper>
  );
});

export default withTranslation('common')(Areas);

import React from 'react';

import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  Autocomplete,
  Marker,
  Polygon
} from '@react-google-maps/api';


import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { toJS } from 'mobx';
import { toast } from 'react-nextjs-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/Spinner/Spinner';
import agent from 'utils/agent';
import { withTranslation } from 'i18n';

import { BiCurrentLocation } from 'react-icons/bi';
import { HiLocationMarker } from 'react-icons/hi';
import { Business } from 'utils/_agent-business';
import { MapWrapper } from './Map.style';
import { getThemeColor } from 'utils/_helper';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const options = {
  disableDefaultUI: false,
  zoomControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  // zoom: 17,
  // streetView: false,
  streetViewControl: false
};

const polygonOptions = {
  fillColor: 'lightblue',
  fillOpacity: 0.4,
  strokeColor: '#9c99ff',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
};

const libraries = ['places'];

function Map({ onAreaSelect }) {
  const router = useRouter();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ? 'AIzaSyBR3DGuhpJjDbrdj2MiAnY3o_hahG2JtzI' : '',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GMAP_KEY,
    libraries: libraries
  });

  const { restaurant, orderType } = router.query;

  const { cartStore } = useStores();

  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(7);
  const [marker, setMarker] = React.useState(null);
  const [autocomplete, setAutocomplete] = React.useState(null);
  //   const [options, setOptions] = React.useState({disableDefaultUI: true,});
  const [areas, setAreas] = React.useState([]);
  const [polygons, setPolygons] = React.useState([]);
  const [deliveryAreas, setdelivery_areas] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [bounds, setBounds] = React.useState({});
  const [center, setCenter] = React.useState({
    lat: 23.4241,
    lng: 53.8478
  });

  React.useEffect(() => {
    Business.all(`/business/${restaurant}/polygons`)
      .then(async (res) => {
        // console.log('POLYGINS LOADED', res);
        let p = [];
        if (res && res.length) {
          res.map((r) => p.push(r.polygon));
          setPolygons(p);
        } else {
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 404) {
        }
      });
  }, []);

  React.useEffect(() => {
    if (map && (!center || !center.lat)) reCenter();
    // console.log('PATH', router.pathname);
  }, [map]);

  React.useEffect(() => {
    if (
      cartStore.currentGeoLocation &&
      cartStore.currentGeoLocation.lat &&
      cartStore.currentGeoLocation.lng
    ) {
      // console.log('HAS GEO:', toJS(cartStore.currentGeoLocation));
      setCenter(toJS(cartStore.currentGeoLocation));
      setZoom(17);
    }
    else {
      reCenter();
    }
  }, [cartStore.currentGeoLocation]);

  React.useEffect(() => {
    // console.log('AREAS:', router.query);
    // api.defaults.headers.common['restaurant-id'] =
    //   cartStore.currentRestaurant.id;

    // console.log('SETTING POLYGONS');
    const p = [];

    // if (
    //   cartStore.currentRestaurant &&
    //   cartStore.currentRestaurant.outlets &&
    //   cartStore.currentRestaurant.outlets.length
    // ) {
    //   cartStore.currentRestaurant.outlets.map((o) => {
    //     if (o.delivery_areas && o.delivery_areas.length) {
    //       deliveryAreas.push(o.delivery_areas);
    //       o.delivery_areas.map((d) => {
    //         p.push(d.polygon);
    //       });
    //     }
    //   });
    // }
    // console.log(p);
    setPolygons(p);
  }, [cartStore.currentRestaurant]);

  const convertToLatLng = (a) => {
    return a.map((e) => ({
      lat: e.lat ? e.lat : e.latitude,
      lng: e.lng ? e.lng : e.long ? e.long : e.longitude
    }));
  };

  React.useEffect(() => {
    if (
      cartStore.isOutletChanged &&
      cartStore.currentOutlet &&
      cartStore.currentOutlet.id
    ) {
      // console.log('FINALLYYY:::', cartStore.currentOutlet.id);
    }
  }, [cartStore.currentOutlet]);

  React.useEffect(() => {
    // console.log('CENTER:', center);
    if (map) {
      map.setCenter(center);
    }
  }, [center]);

  const reCenter = () => {
    setZoom(10);
    if ('geolocation' in navigator) {
      // console.log('Available');
      navigator.geolocation.getCurrentPosition(function (position) {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        setZoom(17);
        // console.log('Latitude is :', position.coords.latitude);
        // console.log('Longitude is :', position.coords.longitude);
      });
    } else {
      setCenter({
        lat: 23.4241,
        lng: 53.8478
      });
      setZoom(8);
      // console.log('Not Available');
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onLoadAutoComplete = (a) => {
    setAutocomplete(a);
  };

  const onLoadMarker = (m) => {
    setMarker(m);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const loc = autocomplete.getPlace().geometry.location;
      // console.log(loc.lat(), loc.lng());
      setCenter(loc);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const onCenterChanged = () => {
    // marker.setPosition(map.getCenter());
    if (map) {
      setCenter(map.getCenter());
    }
    if (marker) {
      marker.setAnimation('DROP');
    }
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onClick = async () => {
    // console.log('CENTER::', center.lat());

    const outlet = await cartStore.loadOutlet(
      { lat: center.lat(), lng: center.lng() },
      'map'
    );

    cartStore.setGeoLocation({ lat: center.lat(), lng: center.lng() });
    // return;
    if (outlet) {
      const gmgeocoder = new google.maps.Geocoder();

      gmgeocoder.geocode({ latLng: center }, function (results, status) {
        // console.log('LOADED ADDRESS:::', results, status);
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            // console.log('ADDRESS:::', results[0], results[0].formatted_address);
            cartStore.setTempAddress(results[0].formatted_address);
          }
        } else {
          // console.log('ADDRESS NOT FOUND');
          // document.getElementById(aElementId).innerHTML = "Geocoder not possible";
        }
      });
      if (outlet && router.pathname == '/[restaurant]/[orderType]') {
        // console.log('MOVING TO STORE');
        if (cartStore.currentRestaurant.domain)
          router.push(`/delivery/${outlet.url}`);
        else
          router.push(
            `/[restaurant]/[orderType]/[store]`,
            `/${restaurant}/delivery/${outlet.url}`
            // { shallow: true }
          );
      }
    }

    onAreaSelect(outlet);

    cartStore.setOrderTypeFinished();

    // if (area.id && router.pathname === "/[restaurant]") {
    //   // console.log("Loading Outlet From Area::");
    //   loadOutlet(area);
    // }
  };

  const onClickConfirm = async () => {
    let found = false;
    let area = null;

    if (!area) {
      toast.notify(`Sorry, we dont deliver in this area.`, {
        title: 'Outside delivery area',
        type: 'error'
      });

      return;
    }

    // alert('outlet found');
    // return;
    const outlet = await cartStore.loadOutlet(area.id);

    cartStore.setGeoLocation({ lat: center.lat(), lng: center.lng() });

    if (outlet && router.pathname == '/[restaurant]/[orderType]') {
      if (cartStore.currentRestaurant.domain)
        router.push(`/delivery/${outlet.url}`);
      else
        router.push(
          `/[restaurant]/[orderType]/[store]`,
          `/${restaurant}/delivery/${outlet.url}`
        );
    }
    onAreaSelect(area);

    cartStore.setOrderTypeFinished();
  };

  const startMarkerAnimation = () => {
    // console.log('Start Drag');
    setDragging(true);
  };

  const endMarkerAnimation = () => {
    // console.log('End Drag')
    setDragging(false);
  };

  return isLoaded ? (
    <MapWrapper>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onDragStart={startMarkerAnimation}
        onDragEnd={endMarkerAnimation}
        onUnmount={onUnmount}
        onCenterChanged={onCenterChanged}
        onBoundsChanged={onCenterChanged}
        options={options}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {/* {
          deliveryAreas && deliveryAreas.map(deliveryArea => {
            // if (!area.geo_detail.polygon) return null;
            return (
              <Polygon
              paths={(deliveryArea.polygon)}
              options={polygonOptions}
              key={deliveryArea.id}
              />
              )
            })
          } */}
          <Polygon
            paths={polygons}
            options={polygonOptions}
            // key={deliveryArea.id}
          />
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={onPlaceChanged}
            restrictions={{ country: 'ae' }}
          >
            <div className="absolute top-5 left-4 right-4 z-50">
              <input className="w-full p-3 border border-gray-400 rounded" />
              <button
                className="absolute right-4 top-0 bottom-0"
                onClick={() => reCenter()}
              >
                <BiCurrentLocation size={22} />
              </button>
            </div>
          </Autocomplete>
          {/* <Marker onLoad={onLoadMarker} position={center} animation="BOUNCE" /> */}
        </>
      </GoogleMap>
      <span
        className={`map-marker-shadow ${dragging ? 'dragging' : ''}`}
      ></span>
      <span className={`map-marker ${dragging ? 'dragging' : ''}`}>
        <HiLocationMarker
          size="2.5rem"
          color={getThemeColor(cartStore.currentRestaurant)}
        />
      </span>
      <div className="absolute bottom-5 left-4 right-4 z-50">
        <button className="btn btn-primary" onClick={() => onClick()}>
          Confirm Location
        </button>
      </div>
    </MapWrapper>
  ) : (
    <></>
  );
}

export default observer(Map);
